import ReactDOMClient from "react-dom/client";
import { WorldGlobe } from "./components/WorldGlobe";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import "./styles/app.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000,
    },
  },
});
const root = ReactDOMClient.createRoot(document.getElementById("root")!);

root.render(
  <QueryClientProvider client={queryClient}>
    <WorldGlobe />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
