  export const getColour = (key: string, data: boolean = false) => {
    switch (key) {
      case "partners":
        return data ? "#004a59" : "#abe9f5";
      case "consumer":
      case "onlinebrands":
        return data ? "#800055" : "#f6a6db";
      case "p2p":
        return data ? "#ffaa00" : "#fae3b6";
      case "checkout- moneygram":
      case "digicel":
      case "b2b":
        return data ? "#599eff" : "#e0e9f8";
      case "checkout- western union":
      case "whitelabel":
        return data ? "rgb(0,0,0)" : "rgb(0,0,0)";
    }
    return "#004a59";
  };