import axios from "axios";
import { Transactions } from "../types/transactions";
import apiClient from "./httpCommon";
// import axios from "axios";
export const getMockTransactions = async () => {
 const { data } = await axios.get<Transactions>("./json/response.json");
  return data;
};

export const getTransactions = async () => {
  const { data } = await apiClient.get<Transactions>(
    "/api/data"
    //const { data } = await axios.get<Transactions>("./json/response.json");
  );
  return data;
};
