import { useEffect } from 'react';

const usePageRefresh = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Reload the page
      window.location.reload();
    }, 15* 60 * 1000); // 15 minutes in milliseconds

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // The empty dependency array ensures the effect runs only once on mount
};

export default usePageRefresh;