export const ARC_REL_LEN = 2.5; // arc length
export const FLIGHT_TIME = 700; // duration of arc animation from start to finish
export const ARC_TIME = FLIGHT_TIME + 1500; // time before ring is processed after arc animation ends
export const NUM_RINGS = 5; // number of rings to show on the arc landing position
export const RINGS_RADIUS_SIZE = 4; // radiuns of the rings
export const TOPUP_ARC_TIME = 1000; // time before next arc is processed
export const REFETCH_DATA = 35000; // * TOPUP_ARC_TIME; // time every refetch occurs
export const RING_PROPAGATION_SPEED = 5; // rings animation speed deg/sec
export const MAP_CENTER = {
  // position where the globe rotation animation starts
  lat: 30,
  lng: 50,
  altitude: 1.7,
};
